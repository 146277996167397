<template>
  <section>
    <hs-group :label="$t('components.automation-input.components.tag-input.title')" label-class="font-weight-bold">
      <MXMultiSelect
        v-model="categories"
        :placeholder="$t('components.automation-input.components.tag-input.placeholder')"
        label="name"
        track-by="id"
        :options="options"
        multiple
        taggable
        @tag="addTag"
      />
    </hs-group>

    <div class="d-flex justify-content-end mt-3">
      <MXButton variant="secondary" @click="$emit('cancel')">{{ $t('actions.cancel-and-back') }}</MXButton>

      <MXButton class="ml-3" variant="primary" @click="onSave">
        {{ $t('actions.save') }}
      </MXButton>
    </div>
  </section>
</template>

<script>
import { studentService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import MXButton from '@/shared/components/MXButton.vue';
import MXMultiSelect from '@/shared/components/MXMultiSelect.vue';

export default {
  name: 'TagInput',
  components: {
    MXButton,
    MXMultiSelect,
  },
  data() {
    return {
      form: {
        category_ids: [],
      },
      categories: [],
      options: [],
    };
  },
  props: {
    automation: {
      type: Object,
      default() {
        return { scope: 'User', action: 'add', category_ids: [] };
      },
    },
  },
  methods: {
    onSave() {
      this.form.category_ids = this.categories.map(val => val.id);

      this.$emit('save', {
        integration: 'Student::Categorization',
        settings: { scope: 'User', category_ids: this.form.category_ids, action: 'add' },
      });
    },
    async addTag(newName) {
      try {
        const {
          data: { id, name },
        } = await studentService.createUserCategory({
          name: newName,
        });

        this.options.push({ id, name });
        this.categories.push({ id, name });
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t(
            'sparkmembers.contents.views.editor.components.lesson.components.automations.tag-input.toast.new-tag.error'
          )
        );
      }
    },
    loadAllCategories(page = 1) {
      return new Promise(resolve => {
        studentService
          .getUserCategories([
            {
              key: 'page',
              value: page,
            },
            {
              key: 'per_page',
              value: '50',
            },
          ])
          .then(({ categories }) => {
            if (categories.length > 0) {
              this.options = this.options.concat(categories);
              return resolve(this.loadAllCategories(page + 1));
            }
            return resolve();
          });
      });
    },
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.automation));

    this.loadAllCategories().then(() => {
      const category_ids = this.form.category_ids || [];
      this.categories = this.options.filter(item => category_ids.includes(item.id));
    });
  },
};
</script>

<style lang="scss" scoped>
/deep/ .multiselect > * {
  min-height: 45px !important;
}

/deep/ .multiselect__tags {
  outline: #7427f1 solid 1px !important;
}

/deep/ .multiselect__single {
  display: flex;
  align-items: center;
  min-height: 45px;
}

/deep/ .multiselect__option:hover {
  background-color: #ead3fe;
  border: #7427f1 1px solid !important;
  color: #262626 !important;
}

/deep/ .multiselect__option--selected {
  background-color: #7427f1 !important;
  border: #7427f1 1px solid !important;
  color: #fff !important;
}
</style>
